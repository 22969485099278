<template>
  <div class="device-repair-wrapper">
    <div class="header-box">
      <div class="icon">
        <img :src="userIcon" alt="userIcon" />
      </div>
      <div class="text">
        <h3>{{ detailData.actualCheckUserName }}</h3>
        <span>{{ detailData.approvalFlag ? '已审批' : '等待审批' }}</span>
      </div>
    </div>
    <div class="basic-box">
      <ul>
        <li>
          <span>点检项：</span><span>{{ detailData.totalCheckNum }}</span>
        </li>
        <li>
          <span>正常点检：</span><span>{{ detailData.totolNormalNum }}</span>
        </li>
        <li>
          <span>异常点检：</span><span>{{ detailData.totolAbnormalNum }}</span>
        </li>
        <li><span>无需点检：</span><span>0</span></li>
      </ul>
      <div class="detail" @click="handleGoCheckDetail">
        <span>查看点检详情</span>
        <i class="iconfont icontiaozhuanqianwangyoujiantouxiangyouxiayibuxianxing"></i>
      </div>
    </div>
    <div class="step-box">
      <van-steps direction="vertical" :active="detailData.taskStatus > 2 ? 1 : 0">
        <van-step>
          <div class="title">提交任务单</div>
          <div class="time">{{ detailData.checkTime }}</div>
          <div class="status">
            {{ detailData.checkUserName }}提交了点检任务单
          </div>
        </van-step>
        <van-step>
          <div class="title">
            {{ detailData.approvalFlag ? '已审批' : '等待审批' }}
          </div>
          <div class="time" v-if="detailData.approvalFlag">
            {{ detailData.approvalTime }}
          </div>
          <div class="status" v-if="detailData.approvalFlag">
            <span :style="{color:detailData.approvalFlag === 1 ?'#67C23A':'#F56C6C',fontWeight:'bold'}"> {{ detailData.approvalFlag === 1 ? '同意' : '拒绝 ' }}</span>
          </div>
          <div class="status" v-if="!detailData.approvalFlag">
            等待{{ detailData.approvalName }}{{detailData.approvalType=='1'?'所有人的审批':'其中一个人的审批'}}
          </div>
        </van-step>
        <!-- <van-step>
          <div class="status">
            {{ detailData&&detailData.approvalResult }}
          </div>
          <div class="status">
            {{ detailData&&detailData.approvalDesc }}
          </div>
        </van-step> -->
      </van-steps>
    </div>
    <div class="tab-bar-box" v-if="!detailData.approvalFlag">
      <span class="left" @click="postApproval(2)">拒绝</span>
      <span class="right" @click="postApproval(1)">同意</span>
    </div>
    <Dialog2 :show="show" :info="dialogInfo" @handleCloseDialogClick="handleCloseDialogClick" @handleQueryDialogClick="handleQueryDialogClick" />
    <Dialog1 :show="show1" :info="dialogInfo1" @handleQueryDialogClick="handleQueryDialogClick1" />

    <van-dialog v-model="rejectDesShow" title="审批意见" show-cancel-button @confirm='confirmRejectDes(2)'>
      <van-field label-width='2rem' v-model="approvalDesc" rows="2" autosize label="意见：" type="textarea" maxlength="200" placeholder="请输入意见" show-word-limit />
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import Dialog1 from './components/Dialog'
import Dialog2 from '@/components/Dialog'
import { Step, Steps, Toast, Icon } from 'vant'
import { Dialog } from 'vant'
import { Field } from 'vant'
import { postApprovalDetail, postApproval } from '@/api/approval'
import userIcon from '@/assets/img/my/user-icon.png'
import { getPreCheckStatus } from '@/api/check'
Vue.use(Step)
Vue.use(Steps)
Vue.use(Toast)
Vue.use(Icon)
Vue.use(Dialog)
Vue.use(Field)
export default {
  name: 'ApprovalDetail',
  components: {
    Dialog1,
    Dialog2,
  },
  data() {
    return {
      userIcon,
      detailData: {},
      currentTaskId: 0,
      dialogInfo: {
        question: '执行过程中其他人无法进入该点检任务，是否开始点检？',
        cancerTxt: '取消',
        queryTxt: '确定',
      },
      dialogInfo1: {
        question: '当前张三正在执行点检任务，您无法进入',
      },
      show1: false,
      show: false,
      rejectDesShow: false,
      approvalDesc: '',
    }
  },
  mounted() {
    this.postApprovalDetail()
  },
  methods: {
    /**
     * 进入点检任务线路前确认接口
     */
    getPreCheckStatus(id) {
      this.currentTaskId = id
      getPreCheckStatus(id).then((res) => {
        if (res.retCode === 0) {
          //异常
          this.show1 = true
          this.dialogInfo1.question = res.retMsg
        }
        if (res.retCode === 1) {
          //正常
          this.show = true
          this.dialogInfo.question = res.retMsg
        }
      })
    },
    /**
     * 点检详情
     */
    handleGoCheckDetail() {
      const taskId = this.$route.query.taskId
      this.getPreCheckStatus(taskId)
      this.$router.push({
        path: '/approvalTaskList',
        query: {
          taskId: taskId,
        },
      })
    },
    handleCloseDialogClick() {
      this.show = false
    },
    handleQueryDialogClick() {
      this.$router.push({
        path: '/taskList',
        query: {
          taskId: this.currentTaskId,
        },
      })
      this.show = false
    },
    /**
     * 弹窗确认（当前张三正在执行点检任务，您无法进入）
     */
    handleQueryDialogClick1() {
      this.show1 = false
    },
    /**
     * 获取详情
     */
    postApprovalDetail() {
      const taskId = this.$route.query.taskId
      postApprovalDetail(taskId).then((res) => {
        this.detailData = res
      })
    },
    /**
     * 审批
     */
    postApproval(approvalFlag) {
      const taskId = this.$route.query.taskId
      if (approvalFlag == '1') {
        postApproval(taskId, {
          approvalFlag,
          taskId,
          approvalDesc: '',
        }).then((res) => {
          if (res.retCode === 1) {
            Toast.success(res.retMsg)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          } else {
            Toast.fail(res.retMsg)
          }
        })
      } else {
        this.rejectDesShow = true
      }
    },
    confirmRejectDes(approvalFlag) {
      const taskId = this.$route.query.taskId
      postApproval(taskId, {
        approvalFlag,
        taskId,
        approvalDesc: this.approvalDesc,
      }).then((res) => {
        if (res.retCode === 1) {
          Toast.success(res.retMsg)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else {
          Toast.fail(res.retMsg)
        }
      })
    },
  },
  filters: {
    filterRejectName(val) {
      console.log(val)
      if (val != '') {
        var nameList = val.split(',')
        let name = nameList[nameList.length - 1]
        return name
      }
    },
    filterAggreName(val) {
      if (val != '') {
        var nameList = val.split(',')
        let name = []
        for (var i = 0; i < nameList.length - 1; i++) {
          name.push(nameList[i])
        }
        return name.join(',')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';
.device-repair-wrapper {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background-color: #f1f1f1;
  /deep/.van-overlay {
    z-index: 7;
  }
  .header-box {
    @include wh(100%, 3.6rem);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 1rem;
    .icon {
      @include wh(2.4rem, 2.4rem);
      border-radius: 50%;
      margin-right: 0.5rem;
      img {
        @include wh(100%, 100%);
      }
    }
    .text {
      h3 {
        @include sc(0.75rem, #333);
      }
      span {
        @include sc(0.7rem, #ffa141);
      }
    }
  }
  .basic-box {
    background-color: white;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    ul {
      li {
        @include sc(0.7rem, #666666);
        line-height: 1.7rem;
        display: flex;
        flex-direction: row;

        span:nth-child(1) {
          font-weight: bold;
        }
        span:nth-child(2) {
          display: block;
          width: 0;
          flex: 1;
          color: #666;
        }
      }
    }
    .detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        width: 0;
        flex: 1;
        @include sc(0.6rem, #4a8cff);
        font-weight: bold;
        margin-right: 0.5rem;
      }
      i {
        font-size: 0.8rem;
      }
    }
  }
  .step-box {
    margin-top: 1rem;
    /deep/.van-hairline {
      &::after {
        border: 0 !important;
      }
      .van-step__title {
        .title {
          font-size: 0.6rem;
          font-weight: bold;
        }
      }
    }
    /deep/.van-step__icon {
      font-size: 0.7rem;
    }
    /deep/.van-step__circle {
      @include wh(0.6rem, 0.6rem);

      // background-color: #cccccc;
    }
    .title {
      @include sc(0.7rem, #4a8cff);
      margin-bottom: 0.7rem;
    }
    .time {
      @include sc(0.6rem, #999999);
      // margin: 0.7rem 0;
      margin-bottom: 0.7rem;
    }
    .status {
      @include sc(0.7rem, #666666);
      margin-bottom: 0.5rem;
    }
  }
  .tab-bar-box {
    @include wh(100%, 2.3rem);
    background-color: white;
    position: fixed;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    border-top: 1px solid #e6e6e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      display: block;
      height: 100%;
      font-size: 0.7rem;
      @include CC;
    }
    span.left {
      width: 50%;
      color: #666666;
    }
    span.right {
      width: 50%;
      color: white;
      background: linear-gradient(
        90deg,
        rgba(79, 147, 255, 1) 0%,
        rgba(58, 187, 253, 1) 100%
      );
    }
  }
  /deep/.van-dialog {
    .van-dialog__header {
      color: #409eff;
      font-weight: bold;
      border-bottom: 1px solid #999;
      padding-bottom: 0.5rem;
    }
    .van-dialog__content {
      padding: 0.7rem 0.2rem;
    }
    .van-dialog__footer {
      .van-dialog__cancel {
        background: #cccccc;
        .van-button__content {
          .van-button__text {
            color: #fff;
          }
        }
      }
      .van-dialog__confirm {
        background: linear-gradient(90deg, #4f93ff 0%, #3abbfd 100%);
        .van-button__content {
          .van-button__text {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
